@import 'conf/conf';

$left-space: $sidebar-width;

@include scrollbars(.5em, $laranja-medicinae, rgba(0, 0, 0, 0));

html {
  position: relative;
  min-width: 320px;
}

html, body {
  min-height: 100%;
  height: 100%;
  min-width: $resMin;
}

main {
  min-height: 100%;
  position: relative;
  font: 14px/16px $font-family;
  color: $azul-medicinae;
  /*height: 100%;*/
  overflow: hidden;

  @include body-bg();

  .additional-bg {
    display: none;
    @include additional-bg();
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  html {
    overflow: hidden;
    height: 100%;
  }
  body {
    overflow: auto;
    height: 100%;
  }
}

a {
  transition: color 0.5s ease;
  outline: 0 !important;
}

.loginPage {
  position: fixed;
  height: 100%;
  min-height: 100%;
  background: #546575;
  width: 100%;
}

.body-bg {
  display: none;
}

.al-header {
  display: block;
  height: 49px;
  margin: 0;
  background-repeat: repeat-x;
  position: relative;
  z-index: 905;
  color: #444444;
}

.al-main {
  margin-left: $left-space;
  padding: 15px 0 35px 0;
  min-height: 500px;
  /*position: relative;*/
}

.al-footer {
  height: 43px;
  padding: 8px 18px 0px 200px;
  width: 100%;
  margin-top: 20px !important;
  position: absolute;
  display: none;
  bottom: -12px;
  font-size: 13px;
  color: #ffffff;
  background: #374F6C;
  transition: padding-left 0.5s ease;
}

.al-footer-main {
  float: left;
  margin-left: 15px;
}

.al-copy {
  float: left;
}

.al-footer-right {
  float: right;
  margin-right: 12px;
  i {
    margin: 0 4px;
    color: $danger;
    font-size: 12px;
  }
  a {
    margin-left: 4px;
    color: $default-text;
    &:hover {
      color: $danger;
    }
  }
}

.al-share {
  margin: -6px 0 0 12px;
  padding: 0;
  list-style: none;
  float: left;
  li {
    list-style: none;
    float: left;
    margin-left: 16px;
    i {
      cursor: pointer;
      transition: all 0.1s ease;
      color: white;
      padding: 6px;
      box-sizing: content-box;
      font-size: 16px;
      &:hover {
        transform: scale(1.2);
      }
    }
    i.fa-facebook-square {
      color: $facebook-color;
    }
    i.fa-twitter-square {
      color: $twitter-color;
    }
    i.fa-google-plus-square {
      color: $google-color;
    }
  }
}

.al-content {
  padding: 8px 32px 8px 40px;
}

@media screen and (max-width: $resXS) {
  .al-content {
    padding: 8px 20px;
  }
}

.vis-hidden {
  visibility: hidden;
  position: absolute;
  top: -9999px;
  left: -9999px;
}

.icon-up, .icon-down {
  width: 5px;
  height: 13px;
  display: block;
}

.icon-up {
  @include bg-nr('arrow-green-up.svg');
}

.icon-down {
  @include bg-nr('arrow-red-down.svg');
}

.disable-text-selection {
  -webkit-touch-callout: none;
  user-select: none;
}

.align-right {
  text-align: right
}

.amcharts-chart-div > a {
  font-size: 6px !important;
}

.content-panel {
  padding-left: 22px;
  padding-top: 26px;
}

@media (max-width: 590px) {
  .al-footer-right {
    float: none;
    margin-bottom: 19px;
    margin-right: 0;
  }
  .al-footer {
    height: 76px;
    text-align: center;
  }
  .al-main {
    padding-bottom: 76px;
  }
  .al-footer-main {
    float: none;
    display: inline-block;
  }
}

.full-invisible {
  visibility: hidden !important;
  * {
    visibility: hidden !important;
  }
}

.irs-grid-text {
  color: $default-text;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.card {
    background: #374f6c;
    padding: 10px;
}

.card table td {
    padding: 6px !important;
    vertical-align: middle;
}

.card table td em {
  font-size: 13px;
}

.default-form {
    width: 50%;
}

@media screen and (max-width: 560px) {
  .al-logo img {
      width: 170px !important;
  }


  a.al-logo {
      width: 170px !important;
      display: inline-block !important;
      float: none !important; 
  }  
}


@media screen and (max-width: 900px) {
  .default-table .select .divider {
      display: none !important;
  }  

  .default-table .select a {
      width: 100%;
      margin: 2px 0;      
  }  

  .default-table .select .actions {
    text-align: center !important;
    margin: 15px 0;
  }  
  
  .default-table .select {
      text-align: center;
  }  
}

.btn-primary {
  position: relative;
}

.btn-action {
  background: $laranja-medicinae !important;
  border-color: $laranja-medicinae !important;
}

.myHighlight {
  background-color: yellow;
}

// Date Picker

.dp-daypicker-input {
    border: 1px solid #374f6c;
    padding: 4px 5px;
    border-radius: 5px;
    margin: 0 5px;
    width: 100%;
    color: #374f6c;    
}

.dp-calendar-container {
    padding: 5px;
    width: 295px;    
}

.dp-weekdays .dp-calendar-weekday {
    padding: 11px 5px;
    border: 1px solid #374f6c !important;
    font-weight: bold;
    color: #fff;
    background: #f6921e;
}

.dp-calendar-weekday {
    width: 40px !important;
}

.date-picker-group .icon i {
    font-size: 18px !important;
}

.date-picker-group .icon {
    position: absolute;
    top: 4px;
    right: 3px;
}

.date-picker-group {
    display: inline-block;
    position: relative;
    width: 95%; 
}

.search-row .date-picker-group:nth-child(1) {
    margin-bottom: 7px;
}

dp-day-picker {
    width: 100%;
}

.dp-calendar-day {
    background: #fff !important;
    width: 40px !important;
    height: 40px !important;
    background: 0;
    border: 1px solid #374f6c;
}

.dp-calendar-nav-container {
    border: 0 !important;
    margin-bottom: 5px;
}

.dp-calendar-nav-container button {
    background: #fff;
    color: #374f6c;
    border: 0;
    padding: 4px 10px;
    border-radius: 5px;
}

.dp-calendar-nav-left {
    left: 2px !important;
}

.dp-calendar-nav-right {
    right: 1px !important;
}

.date-picker-remuneracao .dp-calendars-container {
    right: -285px;
    top: -272px;
    z-index: 99;
}

.date-picker-remuneracao .dp-daypicker-input {
    height: 35px;
    margin-bottom: 15px !important;
    margin: 0;
}

.dp-calendars-container {
    background: #374f6c !important;
    margin-top: 5px;
    z-index: 1;
    /*left: -75px;*/
}

.dp-calendar-month {
  font-weight: bold;
  color: #fff;
}
.ng2-auto-complete {
    padding-left: 10px;    
} 

.ng2-auto-complete input {
    padding: 0 10px; 
    width: 370px !important;   
}

.ng2-auto-complete-wrapper {
  z-index: 5;
}

.slimScrollBar {
    background: #f37021 !important;
    opacity: 1 !important;
}

.typeahead-input {
    display: block;
    width: 100%;
    padding: 0.5rem 0.75rem;
    font-size: 1rem;
    line-height: 1.25;
    color: #55595c;
    background-color: #fff !important;
    background-image: none;
    background-clip: padding-box;
    border: 1px solid #374f6c !important;
    border-radius: 0.25rem;
    margin-bottom: 20px;
}

.add-form label {
    margin-bottom: 5px;
    color: #374F6C;
    font-weight: 400;
    font-size: 13px;  
}

.typeahead-input {
    padding: 6.6px 10px !important;
}

.default-table table thead {
    background: #2b2b2b;
}

.default-table table thead th {
    border-color: #fff;
    color: #fff;
    background: #2b2b2b;
}

.default-table table thead th.active {
  background: #474546 !important;
}

.page-actions div {
  color: #2b2b2b;
  margin-right: 35px;
  margin-bottom: 5px !important;
}

.page-actions div:last-child {
  margin: 0;
}

.page-actions .pagination {
    color: #2b2b2b !important;
}

.default-table .add-element {
    margin-bottom: 35px;
}

.default-table .page-actions select {
    background: #fff;
    border-radius: 5px;
    border-color: #e1e1e1;
}

// .default-table .table td a {
//   color: #2b2b2b !important; 
// }

.alertas {
    position: relative;
    margin-top: 20px;
}

.logo-side {
    color: #ffffff;
    display: block;
    font-size: 24px;
    font-family: "Roboto", sans-serif;
    margin: 30px 10px;
    text-align: center;

  img {
    width: 235px;      
  } 
}

.al-sidebar .user-profile {
  float: none;
  min-width: 230px;
  margin-top: 10px;
  border-top: 1px solid #3e3e3e;
  box-shadow: 1px 7px 22px 0px #1b1b1b;
  margin-bottom: 20px;
}

.al-sidebar .al-user-profile {
    float: none;
    margin-right: 12px;
    transition: all .15s ease-in-out;
    color: #374F6C;
    width: 100%;
    text-align: center;
    border: 0;
    opacity: 1;
    position: relative;
    padding: 25px 0;
  a {
    display: block;
    color: #b3b3b3;
    cursor: pointer;
  }
  a:hover {
    color: #fff;
  }
  img {
    width: 45px;
    height: 45px;
    border-radius: 50%;
  }
}

.al-sidebar .top-dropdown-menu {
  width: 316px;
  left: auto;
  right: -47px;
  top: 26px;
  @include scrollbars(.4em, rgba(0,0,0,0.5), #fff);
  .header {
    padding: 10px 12px;
    border-bottom: 1px solid $default;
    font-size: 12px;
    strong {
      float: left;
      color: $dropdown-text;
    }
    & > a {
      float: right;
      margin-left: 12px;
      text-decoration: none;
      &:hover {
        color: $dropdown-text;
      }
    }
  }
  .msg-list {
    max-height: 296px;
    overflow: scroll;
    overflow-x: hidden;
    & > a {
      border-top: 1px solid $default;
      padding: 10px 12px;
      display: block;
      text-decoration: none;
      color: $dropdown-text;
      font-size: 12px;
      &:first-child {
        border-top: none;
      }
      .img-area {
        float: left;
        width: 36px;
        img {
          width: 36px;
          height: 36px;
          &.photo-msg-item{
            border-radius: 18px;
          }
        }
        & > div {
          width: 36px;
          height: 36px;
          border-radius: 4px;
          font-size: 24px;
          text-align: center;
          &.comments {
            color: $warning;
          }
          &.orders {
            color: $warning;
          }
          i {
            width: 36px;
            line-height: 36px;
          }
        }
      }
      .msg-area {
        float: right;
        width: 230px;
        div {
          max-height: 34px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        span {
          font-style: italic;
          text-align: right;
          display: block;
          font-size: 11px;
        }
      }
      &:hover {
        background: #E2F0FF;
      }
    }
  }
  & > a {
    border-top: 1px solid $default;
    display: block;
    text-align: center;
    padding: 10px;
    font-size: 12px;
    text-decoration: none;
    &:hover {
      color: $dropdown-text;
    }
  }

  &.profile-dropdown {
    width: 145px;
    top: 55px;
    left: 0;
    right: 0;
    margin: 0 auto;
    a {
      text-align: left;
      border: none;
      text-decoration: none;
      color: $dropdown-text;
      padding: 4px 16px 4px 20px;
      &.signout {
        border-top: 1px solid $default;
      }
      i {
        margin-right: 10px;
      }
      &:hover {
        background: 0;
      }
    }
    i.dropdown-arr {
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }

  i.dropdown-arr {
    position: absolute;
    top: -22px;
    right: 42px;
    display: block;
    width: 0;
    height: 0;
    border: 11px solid transparent;
    border-bottom-color: rgba(0, 0, 0, .15);
    &:after {
      top: -9px;
      left: 0px;
      margin-left: -10px;
      content: " ";
      position: absolute;
      display: block;
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-bottom-color: $default;
    }
  }
}

.default-table>.row {
    width: 100%;
}

.completer-input {
    height: 35px;
    width: 100%;
    border: 1px solid #374f6c;
    border-radius: 5px;
    padding-left: 10px;
}

.detalhe-tabela-remuneracao input[type="date"] {
    display: block;
    width: 100%;
    height: 35px;
    border: 0;
    border: 1px solid #4e637c;
    border-radius: 5px;
    margin-bottom: 15px;
    padding-left: 10px;
}

.detalhe-tabela-remuneracao input[type="date"]::-webkit-inner-spin-button {
    display: none;
}

.mb10 {
  margin-bottom: 10px;
}

ngb-typeahead-window.show {
  overflow: scroll !important;
  max-height: 310px;
  display: block !important;
}

@media only screen and (max-width: 767px) {

  .loginPage .loginPanel {
    margin-top: 10px !important;
  }

  .al-main {
    margin-left: 40px;
  }

  .page-actions {
    .total, .count {
      display: none !important;
    }
  }

  .pull-right, .add-element {
    float: none !important;
    margin: 0 !important;

    button {
      margin-left: 0 !important;
      margin-bottom: 10px !important;
    }
  }

  .default-form {
    width: 97%;
  }

  .default-form .btn {
    display: block;
    width: 100%;
    margin-bottom: 6px;
  }

  .al-sidebar.collapsed {
    width: 45px !important;

    .btn-collapse {
      width: 44px;
    }

    .btn-collapse:focus {
      outline: none !important;
    }

    .al-sidebar-list {
      display: none;
    }    
  }

  .al-sidebar-list {
    padding-top: 30px !important;
  }

  .al-sidebar {
    overflow: auto !important;
  }

  button:focus {
    outline: none !important;
  }

  .filter select {
    max-width: 255px !important;
  }
  
  .default-form .default-table {
    overflow: scroll;
  }

}

.completer-dropdown {
  overflow: scroll;
  height: 300px;;
}
 
.boolean-search-select {
  background: 0;
  border: 1px solid #374f6b;
  border-radius: 5px;
  cursor: pointer;
  color: #374f6b;
}

.default-form .btn.btn-primary {
  margin-right: 5px;
}

.filter {
  margin-right: 5px;
}

.add-element button {
  margin-left: 5px;
}

.completer.form-control {
  padding: 0 !important;
  border: 0 !important;
}
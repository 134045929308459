$card-title-height: 44px;
$card-header-font-size: 16px;

.card.card-blur {
  $blurredBgUrl: '/img/blur-bg-blurred.jpg';
  background: url($blurredBgUrl);
  transition: none;
  background-attachment: fixed;

  .card-header, .card-footer {
    background: transparent;
  }
}

.card {
  @include scrollbars(.4em, rgba(0, 0, 0, 0.6), rgba(255, 255, 255, 0.7));
  color: $content-text;
  background-color: $bootstrap-panel-bg;
  border: 0;
  border-radius: $bootstrap-panel-radius;
  position: relative;
  margin-bottom: 24px;
  box-shadow: $bootstrap-panel-shadow;
  &.animated {
    animation-duration: 0.5s;
  }
  &.small-card {
    height: $small-card-height;
  }
  &.xsmall-card {
    height: $xsmall-card-height;
  }
  &.medium-card {
    height: $medium-card-height;
  }
  &.xmedium-card {
    height: $extra-medium-card-height;
  }
  &.large-card {
    height: $large-card-height;
  }
  &.viewport100 {
    height: calc(100vh - 218px);
  }

  &.with-scroll {
    .card-body {
      height: calc(100% - #{$card-title-height});
      overflow-y: auto;
      flex: 0 1 auto;
    }
  }
}

.card {
  > .card-body {
    padding: 15px 22px;
    height: 100%;
  }
  > .card-header {
    color: $default-text;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  > .card-footer {
    color: $default-text;
  }
}

.card-header, .card-footer {
  color: $content-text;
  border-bottom: $bootstrap-panel-header-border;
  height: $card-title-height;
  font-size: $card-header-font-size;
  $vertical-padding: ($card-title-height - $card-header-font-size) * 0.5;
  padding: $vertical-padding 22px;
  background-color: $bootstrap-panel-header-bg;
}

.card-title {
  font-weight: $font-normal;
  font-size: $card-header-font-size;
  text-transform: uppercase;
  opacity: 0.9;
  color: $content-text;
}

.card-primary {
  > .card-header {
    background-color: $primary-bg;
    border-color: $primary-bg;
  }
}

.card-success {
  > .card-header {
    background-color: $success-bg;
    border-color: $success-bg;
  }
}

.card-info {
  > .card-header {
    background-color: $info-bg;
    border-color: $info-bg;
  }
}

.card-warning {
  > .card-header {
    background-color: $warning-bg;
    border-color: $warning-bg;
  }
}

.card-danger {
  > .card-header {
    background-color: $danger-bg;
    border-color: $danger-bg;
  }
}

.accordion-card.card {
  &.card-primary,
  &.card-success,
  &.card-info,
  &.card-warning,
  &.card-danger {
    .card-header {
      p, div, span {
        color: rgba(255, 255, 255, 0.8);
      }
    }
  }
}

.card-group .card.accordion-card {
  .card-header {
    border-bottom: 0;
  }
}

.card-group .card {
  .card-header {
    border-bottom: 1px solid #ddd;
  }
}

.p-with-code {
  line-height: 1.5em;
}

.contextual-example-card {
  height: 120px;
}

.footer-card {
  height: 142px;
}

.light-text {
  font-weight: $font-light;
}

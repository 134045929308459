@-webkit-keyframes spin {
  0% {
    transform: rotate(0deg); /* Firefox 16+, IE 10+, Opera */
  }
  100% {
    transform: rotate(360deg); /* Firefox 16+, IE 10+, Opera */
  }
}

@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg); /* Firefox 16+*/
  }
  100% {
    -moz-transform: rotate(360deg); /* Firefox 16+*/
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg); /* Firefox 16+, IE 10+, Opera */
  }
  100% {
    transform: rotate(360deg); /* Firefox 16+, IE 10+, Opera */
  }
}

#preloader  {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1003;
  background: #ffe8db;
    .loader-wrapper {
      display: flex;
      align-items: center;
      flex-direction: column;
      bottom: 0;
      left: 0;
      right: 0;
      position: relative;
      top: 50%;
      transform: perspective(1px) translateY(-50%);     
      .logo {
        width: 100%;
        text-align: center;        
      }
    }
}

.spinner {
  margin: 50px auto;
  width: 100px;
  height: 40px;
  text-align: center;
  font-size: 10px;
}

.spinner > div {
  background-color: #f6921e;
  height: 100%;
  width: 6px;
  margin: 0 2px;
  display: inline-block;
  
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.4) }  
  20% { -webkit-transform: scaleY(1.0) }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% { 
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }  20% { 
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
  }
}